<!-- 学生选科管理 -- 学科底线配置-- 详情 -->
<template>
  <div class="box" >
    <el-form style="min-height: calc(100vh - 230rem)" ref="form" class="form"
             label-width="96rem">
      <div class="title">基础信息</div>
      <el-form-item label="运营位名称" prop="operation_id">
        <p>{{ dataCont.operation_name }}</p>
      </el-form-item>
      <el-form-item label="校区" prop="school_id">
        <p>{{ dataCont.school_name }}</p>
      </el-form-item>
      <div class="title">学科底线分数设置</div>
      <el-form-item label="物理" prop="chemical">
        <p>{{ dataCont.values[0].score }}</p>
      </el-form-item>
      <el-form-item label="化学" prop="physics">
        <p>{{ dataCont.values[1].score }}</p>
      </el-form-item>
    </el-form>

    <div class="footer-button">
      <el-button type="primary" @click="$router.back()">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
	_config:{"route":{"path":"details","meta":{"title":"详情"}}},
  data() {
    return {
      dataCont: ''
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$_axios2('api/pre-course-select/pc/score-baseline-details?id=' + this.$route.query.id).then(res => {
        if (!(Array.isArray(res.data.data))) {
          this.dataCont = res.data.data
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  .form {
    font-size: 14rem;
    margin: 0 0 20rem 50rem;

    ::v-deep .el-form-item__error {
      margin-left: 80rem;
    }

    .title {
      font-weight: bold;
      font-size: 16rem;
      padding-top: 35rem;
      margin-bottom: 35rem;
    }

    .el-form-item {
      margin-left: 14rem;
    }

    .subject {
      display: flex;
      align-items: center;

      ::v-deep .el-form-item__error {
        margin-left: 0;
      }

      //padding: 15rem 0rem;
      //justify-content: space-around;
      margin-bottom: 15rem;

      .item_box {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 15rem 10rem;
        flex: 1;
      }

      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10rem;

        > span {
          margin-right: 15rem;
        }

        .btn {
          border-radius: 50%;
          width: 30rem;
          height: 30rem;
          text-align: center;
          line-height: 30rem;
          cursor: pointer;
          margin-left: 15rem;
          border: 1rem solid #666666;
        }
      }

      .item.btns {
        width: 100rem;
        justify-content: flex-start;
        text-align: left;

        .btn {
          display: inline-block;
        }
      }

      .el-form-item {
        margin: 0;
      }
    }

    @media only screen and (max-width: 1399rem) {
      .subject {
        .item {
          display: block;
          text-align: center;

          > span {
            display: block;
            margin-right: 0;
            margin-bottom: 15rem;
          }

          ::v-deep .el-input {
            width: 180rem !important;
          }
        }

        .btns {
          width: 130rem;
          justify-content: flex-start;
          text-align: left;

          .btn {
            display: inline-block;
          }
        }
      }
    }
  }
}

.i1 {
  margin-left: 16rem;
}
</style>
